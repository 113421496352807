import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHTML',
  standalone: true
})
export class SafeHTMLPipe implements PipeTransform {

  constructor(private sanitizer:DomSanitizer){}

  transform(html:string) {
    html = html.replace(/\<ul>/g, '<ul class="list-group list-group-flush">');
    html = html.replace(/\<li>/g, '<li class="list-group-item">');
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
